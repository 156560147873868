import { graphql, useStaticQuery } from 'gatsby';
import * as transformers from '@content/transformers/LanguagesPage';

export const languagesPageData = () => {
  const data = useStaticQuery(graphql`
    query languagesPageQuery {
      dGraphQl {
        nodeQuery(
          filter: {
            conditions: {
              field: "type",
              value: "languages_page"
            }
          }
        ) {
          entities {
            ... on DrupalGraphQl_NodeLanguagesPage {
              entityLabel
              entityUrl {
                path
              }
              uuid
              fieldMetaTitle
              fieldWorkInProgressCountries
              fieldMetatags {
                entity {
                  ... on DrupalGraphQl_ParagraphMetatags {
                    fieldDescription
                    fieldKeywords
                    entityBundle
                  }
                }
              }
              fieldBannerTop {
                entity {
                  ...DrupalGraphQlParagraphBannerPage
                }
              }
              fieldInternational {
                entity {
                  ...ParagraphLanguageItem
                }
              }
              fieldTranslations {
                entity {
                  ...ParagraphLanguageItem
                }
              }
              fieldHreflang {
                entity {
                  ...ParagraphHreflang
                }
              }
            }
          }
        }
      }
    }
  `);

  return transformers.languagesPageTransform(data.dGraphQl);
}


