import get from 'lodash.get';

export const languagesPageTransform = (query) =>  {
  const queryData = query.nodeQuery;

  const data = get(queryData, 'entities', []).map(entity => ({
    title: get(entity, 'entityLabel', ''),
    url: get(entity, 'entityUrl.path', ''),
    uuid: get(entity, 'uuid', ''),
    metaData: {
      title: get(entity, 'fieldMetaTitle', ''),
      description: get(entity, 'fieldMetatags.entity.fieldDescription', ''),
      keywords: get(entity, 'fieldMetatags.entity.fieldKeywords', ''),
      hreflang: get(entity, 'fieldHreflang', []).map(item => ({
        code: get(item, 'entity.fieldLang'),
        url: get(item, 'entity.fieldHref.url.path')
      })),
    },
    heroBanner: {
      image: {
        src: get(entity, 'fieldBannerTop.entity.fieldBannerImage.url', ''),
        alt: get(entity, 'fieldBannerTop.entity.fieldBannerImage.alt', ''),
        title: get(entity, 'fieldBannerTop.entity.fieldBannerImage.title', ''),
      },
      imageMobile: {
        src: get(entity, 'fieldBannerTop.entity.fieldBannerImageMobile.url', ''),
        alt: get(entity, 'fieldBannerTop.entity.fieldBannerImageMobile.alt', ''),
        title: get(entity, 'fieldBannerTop.entity.fieldBannerImageMobile.title', ''),
      },
      text: get(entity, 'fieldBannerTop.entity.fieldBannerText.value', ''),
    },
    countries: {
      international: {
        title: get(entity, 'fieldInternational.entity.fieldName', ''),
        url: get(entity, 'fieldInternational.entity.fieldLink.url.path', '/'),
        icon: get(entity, 'fieldInternational.entity.fieldFlag', undefined) ? {
          src: get(entity, 'fieldInternational.entity.fieldFlag.url', ''),
          alt: get(entity, 'fieldInternational.entity.fieldFlag.alt', ''),
          title: get(entity, 'fieldInternational.entity.fieldFlag.title', ''),
        } : undefined
      },
      all: get(entity, 'fieldTranslations', []).map(country => ({
        title: get(country, 'entity.fieldName', ''),
        url: get(country, 'entity.fieldLink.url.path', '/'),
        icon: get(country, 'entity.fieldFlag', undefined) ? {
          src: get(country, 'entity.fieldFlag.url', ''),
          alt: get(country, 'entity.fieldFlag.alt', ''),
          title: get(country, 'entity.fieldFlag.title', ''),
        } : undefined
      })),
    },
    workInProgressText: get(entity, 'fieldWorkInProgressCountries', ''),
  }))

  return data;
}